ion-content {
    --ion-background-color: rgb(255, 255, 255);
}



body {
    margin: 0;
    padding: 0;
    background-color: #e5e1e1 !important;
    font-family: sans-serif;
}

.loggedIn {
    background: #ebebeb;
}

.admin__main {
    position: fixed;
    top: 67px;
    right: 0;
    bottom: 0;
    left: 230px;
    background: white;
    /* border-radius: 20px; */
    box-sizing: initial;
    border-top: 1px solid #dbdada94;
    /* transition: 200ms left; */
}

.admin__main.bodyExpanded {
    left: 0;
}

.navigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    /* border: 10px solid #00b8ff; */
    width: 230px;
    background: #fff;
    transform: none;
    box-shadow: 4px 0px 14px 0px #ccc;
    z-index: 1;
    /* transition: 200ms transform; */
}

.navigation.navHidden {
    transform: translateX(-100%);
    box-shadow: none;
}

.dataTables_scrollHeadInner{
    width: 100% !important;
}

.dataTables_scrollHeadInner table{
    width: 100% !important;
}

.navigation .top-nav {
    /* position: absolute;  */
    top: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
    padding-left: 5px;
    padding-top: 10px;
    padding: 10px 10px;
    margin-bottom: 19px;
}

.navigation ul li a .title {
    position: relative;
    padding-left: 10px;
    display: block;
    line-height: 40px;
    white-space: normal;
}

.navigation ul li a .icon i {
    font-size: 1.5em;
}


.navigation ul li a .title:hover {
    position: relative;
    padding-left: 10px;
    display: block;
    line-height: 40px;
    white-space: normal;
    color: #fff;
}

/*  */
.navigation ul {
    position: relative;
    width: 100%;
    /* border: 10px solid #00b8ff; */
    height: 50%;
    padding-left: 0px;
    /* top: 200px; */
    padding: 10px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: nowrap;

}

@media (min-width: 1517px) {
    .navigation ul {
        height: 100%;
    }
}


.navigation ul li {
    position: relative;
    width: 100%;
    list-style: none;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    /* height: 49px; */
    margin-top: 19px;
    box-shadow: 0px 0px 12px 0px #00000026;

}


.navigation ul li.active {
    position: relative;
    background: #ff0000;
}

.navigation ul li:hover {
    background: #ff0000;
    font-weight: bold;
    color: #fff;
}


.navigation ul li a {
    position: relative;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ff89cb;
}


.navigation ul li.active a {
    color: #fff;
    font-weight: bold;
}

.navigation ul li.active i {
    color: #fff;

}

.navigation ul li:hover i {
    color: #fff;

}

.navigation ul li a .icon {
    position: relative;
    min-width: 37px;
    display: block;
    text-align: center;
    /* padding-top: 5px;*/
}


.navigation ul li a .icon i {
    font-size: 18px;
}


.navigation ul li a .title {
    position: relative;
    /* padding-left: 29px; */
    display: block;
    font-size: 12px;
    font-weight: bold;
    min-width: 110px;
    flex: 1;
    line-height: 40px;
    /* padding-top: 3px; */
    white-space: normal;
}


.navigation ul li a .title:hover {
    position: relative;
    padding-left: 10px;
    display: block;
    line-height: 40px;
    white-space: normal;
    color: #fff;
}


.header {
    position: fixed;
    top: 0;
    right: 0;
    /* border: 10px solid #00b8ff; */
    /* bottom: 500px; */
    left: 230px;
    /* padding: 13px 0px 13px 0px; */
    height: 67px;
    background: #fff;
    /* border-radius: 20px; */
    box-sizing: initial;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* transition: 200ms left; */
}

.header.headerExpanded {
    left: 0;
}

/*  */
.wrapper {
    background: #fff;
    /* border-radius: 20px; */
    max-height: calc(100vh - 70px);
    overflow-y: auto;
    /* margin: 15px 0; */
    padding: 24px;
    min-height: calc(100vh - 70px);
}


.wrapper__view {
    border-radius: 20px;
    max-height: calc(100vh - 130px);
    overflow-y: auto;
    /* margin: 2px 0; */
    /* padding: 27px; */
    padding-left: 22px;
    min-height: calc(100vh - 130px);
}


.cmp-name {
    margin-left: 5px;
}


.profile-pic {
    height: 130px;
    width: auto;
    text-align: center;
    margin-bottom: 15px;
}


.usr-name {
    margin-bottom: 0 !important;
    font-weight: bold;
}


.user {
    text-align: center;
}


.usr-role {
    margin-bottom: 10px;
}


.botom-nav {
    bottom: 0px;
    margin-top: 10px;
    background: #00000057;
    color: #000;
    position: absolute;
    width: 100%;
    /* height: 75px; */
    /* border-bottom-left-radius: 20px; */
    /* border-bottom-right-radius: 20px; */
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    padding: 10px;
}


.botom-nav div:hover {
    cursor: pointer;
}

.botom-nav div {
    margin-bottom: 5px;
}


.header-left {
    padding: 16px 0;
    /* margin-top: 9px; */
    margin-left: 20px;
    font-size: 25px;
    text-align: left;
    font-weight: bold;
}

.header-right a {
    margin-left: 32px;
}


.header-center {
    margin-top: 0px;
    text-align: right;
    display: flex;
    font-size: 20px;
    margin-left: auto;
    align-items: center;
    padding: 0 14px;
    background: rgb(248 38 167 / 12%);
}

.vl {
    border-left: 1px solid #ff9ad7;
    height: 100%;
    margin: 0 12px;
}


.header-right {
    /* padding: 13px; */
    /* margin-top: 10px; */
    margin-right: 16px;
    font-size: 40px;
    text-align: right;
    /* font-weight: bold; */
}

.fa-power-off {
    font-size: 20px;
    /* margin-bottom: 8px; */
}


/* design of scroll bar */


/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}


/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    width: 7px;
    background: #ff0000;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    width: 7px;
    background: #ff0000;
}


.teable__head {
    background-color: #564230;
    color: #fff;
}


.teable__Add__Button {
    color: #fff;
    box-shadow: 0px 0px 12px 0px #00000026;
    text-align: center;
    /* padding-right: 46px; */
    border-radius: 9px;
    /* padding: 22px; */
    height: 14px;
    width: 125px;
    font-weight: bold;
    height: 2.5em;
    margin-left: 0;
    background-color: #fff;
    color: #000;
}

.teable__Add__Button.active {
    background-color: #ff0000;
    color: #fff;
}


.table__upper__part {
    text-align: left;
    position: relative;
    float: none;
}


*:focus {
    outline: 0 !important;
}

.slick-dots {
    left: 0;
}

.overlay {
    display: none;
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 30%);
}

@media (max-width: 767px) {

    .admin__main,
    .header {
        left: 0 !important;
    }

    .wrapper{
        padding: 24px 15px;
    }

    .navigation {
        transform: translateX(-100%);
    }

    .navigation.navHidden {
        transform: none;
        box-shadow: none;
    }

    .overlay {
        display: block;
        pointer-events: all
    }

    .navigation ul{
        height: auto;
    }
}

.imgStatus{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    color: #fff;
    font-weight: 700;
}

.imgStatus.rejected{
    background: rgb(255 0 0);
}

.imgStatus.accepted{
    background: rgb(25 200 98);
}